.select-custom {
  display: flex !important;

  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.275rem 1rem !important;
    gap: 8px;
    background: #ffffff;
    border-radius: 0.4rem !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    font-weight: 400 !important;
    height: 2.1875 !important;

    .MuiIconButton-root {
      // width: 10px !important;
      .Mui-disabled {
        background-color: 'rgba(245, 245, 245, 0.5)';
      }
    }

    &:focus-within {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid !important;
        border-color: #038edb !important;
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #afafaf !important;
      }

      input {
        &::placeholder {
          opacity: 1;
          color: #373737 !important;
          font-size: 0.875rem;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border-color: #afafaf !important;
      }
    }
  }

  input {
    height: auto;
    padding: 0 !important;
    line-height: 1.52857;
    font-size: 0.875rem;
    margin-left: -3px !important;
    margin-right: 7px !important;
  }
}
