.content {
  .MuiPaper-root {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableCell-root {
          padding: unset;
        }
      }
    }
  }
}
