.item-login {
  background-color: #00354e;
  height: 100vh;

  .form-field {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 28px;
    @media (min-width: 720px) {
      width: 45%;
    }

    & > div:first-child {
      margin-bottom: 20px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .view-link {
      margin-top: 12px;
      right: 0px;
      text-align: right !important;
      display: flex;
      align-items: center;
      margin-left: -6px;

      .customLink {
        flex-direction: column;
        color: white;
        text-decoration: none;
        font-size: 13px;
      }
    }

    .btn-login {
      margin-top: 25px;
    }
  }
}

.item-content-right {
  height: 100vh;
  @media (min-width: 768px) {
    width: 100%;
  }

  .container-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .text-welcome {
      color: #105267;
      line-height: 40px;
      font-weight: 600;
      font-size: 32px;
      @media (max-width: 450px) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .text {
      margin-top: 10px;
      font-size: 15px;
      line-height: 24px;
      color: #4d4d4d;
      font-weight: 400;

      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 15px;
      }
    }

    .image-right {
      width: 100%;
    }
  }
}

.login-layout {
  width: 100% !important;
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
