@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/SourceSansPro-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/SourceSansPro-SemiBold.ttf') format('opentype');
}

:root {
  --color-secondary-hover: #f8af3f;
  --color-dark-blue-30: #00354e;
  --color-grey-scale-40: #999999;
  --color-grey-scale-60: #4d4d4d;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container-fluid {
  padding: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.left-side-bar-nav {
  .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }
}

.font-size-1 {
  font-size: 1em;
}

.font-size-0 {
  font-size: 0.875rem;
}

.font-size-12 {
  font-size: 1.2em;
}

.font-size-15 {
  font-size: 1.5em;
}

.isMobile {
  display: none;
  @media only screen and (max-width: 512px) {
    display: block;
  }
}
.isDesktop {
  display: block;
  @media only screen and (max-width: 512px) {
    display: none;
  }
}

.react-calendar {
  border: none;
}