.content {
  .drawer-table {
    width: unset !important;
  }

  .MuiPaper-root {
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableCell-root {
          padding: unset;
        }
      }
      .MuiTableBody-root {
        // background-color: black;

        .MuiTableCell-root {
          .btn-create {
            border: 1px solid #1797be;
            padding: 0.2rem 0.7rem;
            text-align: center;
            justify-content: center;
            margin-left: 0.5rem;
            @media (max-width: 900px) {
              margin-bottom: 0.3rem;
            }

            .txt {
              color: #1797be;
              font-weight: 500;
              font-size: 0.845rem;
              text-transform: none;
              display: inline-block;
            }

            svg {
              color: #1797be;
            }
          }
        }
      }
    }
  }
}
.view-image{
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-image{
  width: 75px;
  height: 78px;
  border-radius: 8px !important;
  border: 1px solid #d7d7d7 !important;
  margin: 0 10px 0 0;
}