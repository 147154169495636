:root {
  --expand: 210px;
  --collapse: 64px;
}
.calendarView {
  .MuiPaper-root {
    top: var(--top) !important;
    left: var(--left) !important;
    @media (max-width: 450px) {
      left: 13px !important;
    }
  }
}
.left-slider-bar {
  .active {
    background-color: #002536;
  }
}

.background-before-inactive {
  text-decoration: none;
  display: flex;
  color: #002536 !important;
  font-size: 14px;
  align-items: center;
}

.background-before-actived {
  text-decoration: none;
  display: flex;
  color: #002536;
  font-size: 0.875rem;
  align-items: center;
}

.dot-before-inactive {
  text-decoration: none;
  display: flex;
  color: #5f5f5f !important;
  font-size: 14px;
  align-items: center;
  &::before {
    display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 4px;
    width: 4px;
    margin-right: 0.5rem;
    background-color: #bdbdbd;
  }
}

.dot-before-actived {
  text-decoration: none;
  display: flex;
  color: white;
  font-size: 0.875rem;
  align-items: center;
  &::before {
    display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 6px;
    width: 6px;
    margin-right: 0.5rem;
    background-color: white;
  }
}

.txt-menu-left {
  color: white;

  svg {
    color: white;
    font-size: 16px;
  }

  span {
    font-size: 0.875rem;
  }
}

.icon-menu-left {
  color: white;
  transform: scaleX(-1);
}

.icon-menu-right {
  color: #4d4d4d;
  transform: scaleX(-1);
}

.icon-toggle {
  color: #00354e;
  background-color: yellow;
}

.icon-profile {
  right: 0;
  margin-right: 5rem !important;
  color: aqua;
}

.home-title {
  color: var(--color-grey-scale-40);
}

.icon-right-header {
  color: var(--color-grey-scale-60);
}

.view-toggle {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

//content-right
.content-right {
  // Handle Responsive drawer

  .drawer-collapse {
    width: calc(100vw - var(--collapse));
  }
  .content-layout {
    // height: calc(100vh);

    background-color: #e7e7e7;
    padding-top: 5px !important;
    padding: unset;
    padding-bottom: 5px;
    .calendarView {
      & .MuiPaper-root {
        background-color: violet !important;
      }
    }

    .page-layout {
      margin: 10px;
      padding: 0.8rem;
      background-color: white;

      .MuiSnackbar-root {
        bottom: unset;
        left: unset;
        top: 24px;
        right: 24px;
      }
      .txt-title {
        color: #4d4d4d;
        font-size: 1rem;
        line-height: 24px;
      }
      .header_title {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-button {
          display: flex;
          justify-content: flex-end;
        }

        .txt-title {
          color: #4d4d4d;
          font-size: 1rem;
          line-height: 24px;
        }

        .btn-create {
          border: 1px solid #1797be;
          padding: 0.2rem 0.7rem;
          text-align: center;
          justify-content: center;
          margin-left: 0.5rem;
          @media (max-width: 900px) {
            margin-bottom: 0.3rem;
          }

          .txt {
            color: #1797be;
            font-weight: 500;
            font-size: 0.845rem;
            text-transform: none;
            display: inline-block;
          }

          svg {
            color: #1797be;
          }
        }

        .btn-disable {
          border: 1px solid #d7d7d7;
          background-color: #d7d7d7;
          padding: 0.2rem 0.7rem;
          text-align: center;
          margin-right: 0.5rem;

          .txt {
            color: black;
            font-size: 0.845rem;
            text-transform: none;
            display: inline-block;
            font-weight: 500;
          }

          svg {
            color: black;
          }
        }

        .item-btn-right {
          flex-direction: row;
          display: flex;
        }
      }

      .table {
        margin-right: 20px;
      }
    }

    .bottom-width {
      width: 10px;
      color: #4d4d4d;
      display: flex;
    }

    .container-table {
      margin-top: 20px;
      width: 100%;

      .view-right-table {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .title-price {
        font-size: 0.875rem;
        color: #4d4d4d;
        white-space: 'nowrap';
      }

      .txt-price {
        color: #f8af3f;
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .search {
      display: flex;
      margin-top: 15px;
      justify-content: space-between;
      @media (max-width: 1210px) {
        display: block;
      }
      .date-picker {
        display: flex !important;

        .MuiOutlinedInput-root {
          border-radius: 0.4rem !important;
          padding: 0.05rem 0.4rem !important;
          .MuiInputAdornment-root {
            margin-left: -10px;
          }
          .MuiOutlinedInput-input {
            padding: 0.275rem 1rem !important;
            font-size: 0.875rem;
            margin-left: -10px !important;
          }
          &:hover {
            .MuiOutlinedInput-notchedOutline {
              border-color: #afafaf !important;
            }
          }
        }
      }

      .view-input {
        margin-right: 0.3rem;
        @media (max-width: 900px) {
          margin-bottom: 0.3rem;
          width: unset !important;
        }
      }

      .view-filter {
        word-wrap: break-word;

        input {
          padding: 0.275rem 0.5rem !important;
        }
      }
      .txt-find {
        font-size: 0.875rem;
      }
      .txt-search {
        margin-top: 4px;
        right: 0px;
      }
    }
  }
}

//btn
.btn-edit {
  &:hover {
    background-color: unset !important;
  }
}

.btn_create,
.btn_excel {
  svg {
    padding-right: 0.5rem;
  }
}

.btn_excel {
  margin-left: 12px !important;
}

.theme {
  border: 1px solid #f1f1f1;
}

// Drawer
.drawer {
  z-index: 1202 !important;

  .MuiPaper-root {
    box-shadow: unset;
    border-radius: unset;

    .title-drawer {
      padding: 1rem 1.75rem 1rem 1.75rem;
      display: flex;

      .title {
        flex-direction: row;
        display: flex;

        span {
          font-size: 1.25rem;
          font-weight: 500 !important;
          color: #373737;
        }
      }

      .btn-close {
        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
          color: #373737;
          font-size: 1rem;
        }

        cursor: pointer;
      }
    }

    .content-drawer {
      .title-group {
        background: rgba(245, 245, 245, 0.5) !important;
        padding: 0.4rem 1.75rem;

        span {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #5f5f5f !important;
        }
      }

      .content {
        margin: 0.5rem 1.75rem;
        .field-note {
          .MuiInputBase-input {
            height: 3rem;
          }
        }

        .MuiGrid-container {
          .MuiGrid-item {
            padding-top: 10px;
          }
        }

        .MuiPaper-root {
          .MuiTable-root {
            .MuiTableHead-root {
              .MuiTableCell-root {
                padding: 7px;
              }
            }

            .MuiTableBody-root {
              .MuiTableCell-root {
                padding: 6px;
              }
            }
          }
        }

        .MuiInputBase-root {
          min-width: auto !important;
          align-items: flex-start;
        }

        .input-note {
          .MuiInputBase-root {
            word-wrap: break-word;
            height: 4.5rem;
            word-break: break-all;
          }
        }
      }
      .image-item {
        position: relative;
        width: 75px;
        height: 78px;
        margin-left: 5px;
        flex-direction: row;
        display: flex;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px !important;
          border: 1px solid #d7d7d7 !important;
        }
        .close {
          position: absolute;
          right: -8px;
          top: -8px;
          color: #ff4d4f;
          cursor: pointer;
          min-width: 0;
          padding: 0;
          svg {
            color: #ff4d4f;
          }
        }
      }

      .view-image {
        align-items: center;
        justify-content: center;
        padding: 1rem 2rem;

        .image-product {
          height: 6rem;
          width: 100%;
          border: 1px dashed #d7d7d7;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: rgba(229, 229, 229, 0.2);
          // svg {
          //   color: #afafaf;
          //   font-size: 3rem;
          // }
        }
      }
    }

    .footer-drawer {
      padding: 1.75rem;
      border-top: 1px solid #d7d7d7;

      .btn-outlined-blue-md,
      .btn-primary-md {
        width: 100% !important;
        border-radius: 0.5rem;
      }
    }
  }

  .btn-add {
    border-bottom: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    width: 100%;
    border-radius: unset;
    .view-btn-add {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: row;
      .empty-data {
        width: 90px;
        height: 70px;
      }
      span {
        font-size: 12px;
        color: #1a89ac;
      }
      svg {
        color: #1a89ac;
        font-size: 15px;
      }
    }
  }
}

.btn-add-product {
  border-bottom: 1px solid #d7d7d7;
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  width: 100%;
  border-radius: unset;
  height: 7rem;
  .view-btn-add {
    padding-top: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .empty-data {
      width: 90px;
      height: 70px;
    }
    span {
      font-size: 12px;
      color: #afafaf;
    }
  }
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.flex-direction-row {
  flex-direction: row !important;
  @media (min-width: 390px) {
    flex-direction: column !important;
  }
}

.flex-direction-col {
  flex-direction: column !important;
}

.flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

// width
.w-100 {
  width: 6.25rem !important;
}

// Margin-top
.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

// Margin-right
.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.75rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

// height

.h-100 {
  height: 95% !important;
}
.h-50 {
  height: 55% !important;
}

// Responsive

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .content-layout {
    height: unset !important;
  }
  .page-layout {
    // margin-left: 0px !important;
    margin-left: 10px !important;
    .header-button {
      display: unset !important;
      justify-content: unset !important;
    }
    .header_title {
      display: block !important;
      .item-btn-right {
        display: block !important;
      }
      .txt-title {
        font-size: 0.8rem !important;
        line-height: 20px !important;
      }
      .btn-create {
        margin-left: unset !important;
        width: 132px !important;
        padding: 0.1rem 0.7rem !important;
        margin-right: 5px !important;
        .txt {
          font-size: 0.7rem !important;
        }
        svg {
          font-size: 15px;
        }
      }
    }

    .search {
      display: block !important;
      .form-control {
        .label {
          font-size: 0.8rem !important;
          margin-bottom: 0.1rem !important;
        }
        .view-input {
          margin-right: unset !important;
          .select-custom {
            input {
              font-size: 0.8rem !important;
            }
          }
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
      .txt-find {
        font-size: 0.8rem !important;
      }
      .search-input {
        svg {
          width: 15px !important;
          height: 15px !important;
        }
        input {
          &::placeholder {
            opacity: 1;
            color: #373737 !important;
            font-size: 0.8rem;
          }
        }
      }
    }

    .container-table {
      .view-right-table {
        display: flex !important;
        justify-content: flex-end !important;
        align-items: center !important;
        padding-bottom: 5px !important;
      }
      .title-price {
        font-size: 0.8rem !important;
      }
      .txt-price {
        font-size: 0.8rem !important;
      }
      .left-border {
        font-size: 0.8rem !important;
      }
      .mid-border {
        font-size: 0.8rem !important;
      }
      .right-border {
        font-size: 0.8rem !important;
      }
    }
  }

  .modal-main {
    .container {
      min-width: unset !important;
      min-height: unset !important;
      .content {
        margin-left: unset !important;
        .title {
          font-size: 0.9rem;
          svg {
            width: 0.8em;
            height: 0.8em;
          }
        }
      }
      .view-input-discount {
        font-size: 0.8rem !important;
        .MuiInputBase-root {
          font-size: 0.8rem !important;
        }
      }
    }
  }

  .drawer {
    .MuiPaper-root {
      width: 80% !important;
    }
    .title-drawer {
      .btn-close {
        svg {
          font-size: 0.9rem !important;
        }
      }
      span {
        font-size: 1rem !important ;
      }
    }
    .content-drawer {
      display: block !important;

      .title-group {
        padding: 0.2rem 1.75rem !important;
        span {
          font-size: 0.9rem !important;
        }
      }

      .content {
        .class-display {
          display: flex;
          flex-direction: row;
        }
        .MuiGrid-root {
          flex-direction: column;
          padding-left: unset !important;
          margin-left: 1px;
        }
        .MuiFormControl-root {
          .MuiTextField-root {
            flex-direction: initial;
          }
        }
        padding-right: 10px !important;
        .MuiInputBase-root {
          width: 100% !important;
        }
        .ckeditor {
          width: 99% !important;
        }
      }
    }
    .footer-drawer {
      .btn-secondary-disable {
        font-size: 0.8rem !important;
        padding: 4px 6px !important;
      }
      .btn-primary-disable {
        font-size: 0.8rem !important;
        padding: 4px 6px !important;
      }
      .btn-tertiary-normal {
        font-size: 0.8rem !important;
        padding: 4px 6px !important;
      }
    }
  }
  .content {
    .MuiGrid-root {
      max-width: unset !important;

      .MuiInputBase-root {
        width: 100% !important;
      }
    }

    .MuiPaper-root {
      width: 100% !important;
    }
  }
  .page-layout-blank {
    .order-detail {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      .button-cancel {
        margin-left: unset !important;
      }
    }
    .grid-container {
      width: calc(100% + 16px) !important;
    }
  }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .drawer {
    @media (max-width: 768px) {
      .MuiPaper-root {
        width: 70% !important;
      }
    }
    .title-drawer {
      .btn-close {
        svg {
          font-size: 0.9rem !important;
        }
      }
      span {
        font-size: 1rem !important ;
      }
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
