.container-table {
  .Target-root {
    #top-container {
      justify-content: center;

      .custom-legend {
        width: 25%;
        text-align: center;

        h2 {
          margin-bottom: 0px;
        }

        ul li span {
          font-size: 0.8rem;
        }
      }
    }
  }
}
