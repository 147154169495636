.slider {
  &-container {
    flex-grow: 0;
  }

  &-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &.__start {
      justify-content: start;
      margin: 10px 0 0;
    }

    div:last-child:has(input) {
      height: 32px;
      border-radius: 7px;
    }

    input {
    }
    ::placeholder {
      font-size: 14px;
    }
  }

  &-icon {
    color: black;
    flex-shrink: 1;
    width: 20px;
    height: 20px;
  }

  &-main {
  }
}
