.expand-table {
  // border: 1px solid #ffffff;
  box-shadow: unset !important;

  .view-row {
    display: flex;
    width: 100%;
  }

  .img-row {
    max-width: 4rem;
    height: 2rem;
  }

  .MuiTable-root {
    // table-layout: fixed;

    .MuiTableRow-hover:hover {
      background-color: #76b8cd1a;
    }

    .MuiTableRow-selected {
      background-color: #76b8cd1a;
    }

    .MuiTableHead-root {
      border: 1px solid #e4e4e4;
      background-color: rgba(245, 245, 245, 0.5);

      .MuiTableCell-root {
        padding: unset;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px solid #e4e4e4;
        font-family: 'SourceSansPro';
        color: #00202f;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        border: 1px solid #e4e4e4;

        .MuiTableCell-root {
          border: 1px solid #e4e4e4;
          font-size: 0.875rem;
          padding: 0.1rem 0.5rem;
          color: #666666;
          .text-color {
            .MuiInputBase-input {
              color: #666666;
            }
          }
        }
      }

      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          .MuiCollapse-wrapperInner {
            .MuiBox-root {
              .MuiTable-root {
                .MuiTableHead-root {
                  background-color: #f1f1f1;

                  .MuiTableRow-root {
                    .MuiTableCell-root {
                      font-family: 'SourceSansPro';
                      color: #00202f;
                    }
                  }
                }

                .MuiTableBody-root {
                  .MuiTableRow-root {
                    .MuiTableCell-root {
                      padding: 0.1rem 0.5rem !important;
                      word-break: break-word;
                      .MuiTouchRipple-root {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
