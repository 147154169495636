.page-layout {
  .view-input-discount {
    display: flex;
    flex-direction: row;
  }
  .calendarView {
    .MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      background-color: violet !important;
    }
  }
  .MuiTabs-root {
    min-height: unset;
    .MuiButtonBase-root {
      text-transform: none;
      height: 40px;
      min-height: unset;
    }
    .MuiTabs-scroller {
      .MuiTabs-indicator {
        background-color: unset;
      }

      .MuiTabs-flexContainer {
        .MuiButtonBase-root {
          background-color: #f1f1f1;
          font-weight: '600';
        }

        .Mui-selected {
          background-color: white;
          color: black;
        }

        .mid-border {
          border-right: 1px solid #e4e4e4;
          border-top: 1px solid #e4e4e4;
        }

        .left-border {
          border-left: 1px solid #e4e4e4;
          border-top: 1px solid #e4e4e4;
        }

        .right-border {
          border-right: 1px solid #e4e4e4;
          border-top: 1px solid #e4e4e4;
        }
      }
    }
  }
}
