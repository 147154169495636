.form-control {
  .label {
    color: #373737;
    margin-bottom: 0.5rem !important;
    font-size: 0.875rem;
  }

  .text-red {
    color: red;
  }

  .text-link {
    font-style: italic;
    color: #1a89ac !important;
  }

  .text-grey-2 {
    color: #afafaf;
  }

  .text-secondary {
    color: #373737;
  }
}
