.calendarView {
  // background-color: red !important;
  .MuiModal-backdrop {
    background-color: red !important;
  }
  & .MuiPaper-root {
    display: none !important;
    background-color: violet !important;
    top: 100px !important;
    right: 600px !important;
  }
  .MuiBackdrop-root {
    background-color: red !important;
  }
}

.calendar {
  width: 400px !important;
  border: none !important ;
  background-color: #fff !important;
  padding: 5px;
  // border-radius: 20px;

  .label {
    font-size: 16px;
    font-weight: 600;
    color: #444444;
    text-transform: capitalize;
  }

  :global {
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: #fff;
    }

    .react-calendar__month-view__weekdays {
      margin-bottom: 12px;

      .react-calendar__month-view__weekdays__weekday {
        font-size: 14px;
        font-weight: 600;
        color: #000;

        abbr {
          text-decoration: none;
        }
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;

      &.react-calendar__tile--range {
        color: #808080;

        background-color: #dce7e8;
      }

      &.react-calendar__tile--rangeStart,
      &.react-calendar__tile--rangeEnd {
        color: #fff;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }

    .react-calendar__tile {
      height: 36px;
      padding: 4px;
      margin: 4px 0;

      font-size: 1.6rem;
      font-weight: 400;

      &--hover {
        color: #808080;
        background-color: #dce7e8;
      }

      &:hover {
        background-color: #dce7e8;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #808285;
    }

    .react-calendar__tile--range {
      color: #808080;
      background-color: #dce7e8;
    }

    .react-calendar button {
      margin: 4px 0;
    }

    .react-calendar__tile--rangeStart {
      color: #fff;
      position: relative;
      background-color: #fff;

      abbr {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px; // 0
        width: 36px; // 100%
        height: 36px; // 100%
        z-index: 2;
        border-radius: 50%;
        background-color: #2942b6;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        background-color: #fff;
      }
    }

    .react-calendar__tile--rangeEnd {
      color: #fff;
      position: relative;
      background-color: #fff;

      abbr {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px; // 0
        width: 36px; // 100%
        height: 36px; // 100%
        z-index: 2;
        border-radius: 50%;
        background-color: #2942b6;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        background-color: #fff;
      }
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background-color: #dce7e8;
    }
  }

  .listDayWaitingRes {
    background-color: red !important;
    color: white;

    border-radius: 50%;
  }

  // div[class*='days'] {
  //   button {
  //     margin-bottom: 4px;
  //     height: 40px;
  //     margin-right: 1%;
  //     border-radius: 50%;
  //     flex-basis: 13.2877% !important;
  //   }
  //   button[class*='now'] {
  //     background: none;
  //     color: gray;
  //   }

  //   button[class*='active'] {
  //     background-color: black;
  //     color: yellowgreen;
  //   }
  // }
}

.calendarOneDay {
  :global {
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: #f9f9f9;
    }

    .react-calendar__month-view__weekdays {
      margin-bottom: 12px;

      .react-calendar__month-view__weekdays__weekday {
        font-size: 14px;
        font-weight: 600;
        color: #000;

        abbr {
          text-decoration: none;
        }
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;

      &.react-calendar__tile--range {
        color: #444444;
        background-color: rgba(41, 66, 182, 0.2);
      }

      &.react-calendar__tile--rangeStart,
      &.react-calendar__tile--rangeEnd {
        color: #fff;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }

    .react-calendar__tile {
      height: 36px;
      padding: 4px;
      margin: 4px 0;

      font-size: 1.6rem;
      font-weight: 400;

      &--hover {
        color: #808080;
        background-color: #dce7e8;
      }

      &:hover {
        background-color: #dce7e8;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: #808285;
    }

    .react-calendar__tile--range {
      color: #444444;
      background-color: rgba(41, 66, 182, 0.2);
    }

    .react-calendar button {
      margin: 4px 0;
    }

    .react-calendar__tile--rangeStart {
      color: #fff;
      position: relative;
      background-color: #fff;

      abbr {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px; // 0
        width: 36px; // 100%
        height: 36px; // 100%
        z-index: 2;
        border-radius: 50%;
        background-color: #2942b6;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        background-color: #fff;
      }
    }

    .react-calendar__tile--rangeEnd {
      color: #fff;
      position: relative;
      background-color: #fff;

      abbr {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px; // 0
        width: 36px; // 100%
        height: 36px; // 100%
        z-index: 2;
        border-radius: 50%;
        background-color: #2942b6;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        background-color: #fff;
      }
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background-color: rgba(41, 66, 182, 0.2);
    }
  }
}

.disableRangeDays {
  :global {
    .react-calendar__tile--range {
      pointer-events: none;
      background-color: #dce7e8;
    }
  }
  .listDayRes {
    pointer-events: none;
    color: #b3b3b3;
  }
}

.disableDate {
  :global {
    .react-calendar__tile--range {
      background-color: #dce7e8;
    }
  }
  .listDayRes {
    pointer-events: none;
    color: #b3b3b3;
  }
}

.listDayOff {
  background-color: red !important;
  color: white !important;

  border-radius: 50%;
}
