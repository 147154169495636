.order-flex {
  display: 'flex' !important;
  justify-content: 'space-between' !important;
}
.order-custom-title {
  font-size: 0.875rem !important;
  // font-family: 'SourceSansPro' !important;
}

.disabled-field {
  .MuiInputBase-root {
    background-color: #edf1f2 !important;
    color: black;

    .Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border: red !important;
      }
    }
  }
}
.disabled-field-high {
  .MuiInputBase-root {
    background-color: #edf1f2 !important;
    .MuiInputBase-input.Mui-disabled {
      -webkit-text-fill-color: #038edb;
    }
  }
}
.order-field-value {
  font-size: 14px !important;
  color: #4d4d4d;
}
.input-detail {
  display: flex !important;
  color: #038edb !important;

  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.275rem 1rem !important;
    gap: 8px;
    background: #ffffff;
    border-radius: 0.4rem !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    font-weight: 400 !important;
    height: 2.1875 !important;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #afafaf !important;
      }

      input {
        &::placeholder {
          opacity: 1;
          font-size: 0.875rem;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border-color: #afafaf !important;
      }
    }

    input {
      padding: 0;

      &::placeholder {
        opacity: 1;
        color: #afafaf;
        font-size: 0.875rem;
      }
    }

    &.Mui-error {
      border-color: #d32f2f !important;
    }
    &:focus-within {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid !important;
        border-color: #038edb !important;
      }
    }
  }
  input {
    height: auto;
    padding: 0 !important;
    line-height: 1.52857;
    font-size: 0.875rem;
  }
}

.page-layout .tracking_order {
  display: flex !important;

  .item-btn-right {
    display: flex !important;
    .btn-create {
      margin-left: 0.5rem !important;
    }
  }
}

.page-layout .products_order {
  display: flex !important;
}

.link-order-detail {
  background-color: unset !important;
  text-decoration: underline !important;
  color: blue !important;
  font-weight: bold !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 380px) and (-webkit-min-device-pixel-ratio: 2) {
  .order-detail {
    margin-bottom: 8px;
    .filter_order_right {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .button-cancel {
        max-width: none !important;
      }
    }
  }

  .item-btn-right {
    justify-content: flex-end;
  }

  .drawer {
    .MuiPaper-root {
      min-width: 80% !important;
    }
    .expand-table {
      width: 100% !important;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .order-detail {
    margin-bottom: 8px;
    .filter_order_right {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .item-btn-right {
    justify-content: flex-end;
  }

  .drawer {
    .MuiPaper-root {
      min-width: 80% !important;
    }
    .expand-table {
      width: 100% !important;
    }

    .content-drawer .content .MuiFormControl-root .MuiTextField-root {
      flex-direction: column;
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .order-detail {
    margin-bottom: 0px;
    .filter_order_right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .item-btn-right {
    justify-content: flex-end;
  }
  .drawer {
    .expand-table {
      width: 100% !important;
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .order-detail {
    margin-bottom: 8px;
    .filter_order_right {
      display: flex;
      justify-content: flex-start;
    }
  }

  .item-btn-right {
    justify-content: flex-end;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .order-detail {
    margin-bottom: 0px;
    .filter_order_right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .item-btn-right {
    justify-content: flex-end;
  }
}
