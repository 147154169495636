.btn-primary-hover {
  width: 100%;
  background-color: #f8af3f !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  align-items: center;

  &:hover {
    background-color: rgba(237, 169, 73, 0.8) !important;
    box-shadow: 0px 4px 4px rgba(77, 77, 77, 0.1) !important;
  }

  &:active {
    background-color: rgba(237, 169, 73, 0.9) !important;
  }
}

.btn-primary-normal {
  width: 100%;
  background-color: #00354e !important;
  color: white !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

.btn-secondary-hover {
  width: 100%;
  background-color: white !important;
  color: var(--color-secondary-hover) !important;
  font-weight: 500 !important;
  border-radius: 8px !important;

  border: 1px solid #f8af3f !important;
}

.btn-secondary-normal {
  width: 100%;
  background-color: white !important;
  color: #00354e !important;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #00354e !important;
}

.btn-tertiary-normal {
  width: 100%;
  background-color: #1a89ac !important;
  color: white !important;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none !important;
  &.Mui-disabled {
    color: #dedede !important;
  }
}

.btn-primary-disable {
  width: 100%;
  background-color: #f1f1f1 !important;
  color: #999999 !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.btn-secondary-disable {
  width: 100%;
  background-color: white !important;
  color: #808080 !important;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #e4e4e4 !important;
  padding: unset;
  text-transform: none !important;
}

.btn-tertiary-disable {
  width: 100%;
  background-color: #f1f1f1 !important;
  color: #999999 !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
}

.btn-tertiary-disable {
  width: 100%;
  color: #1a89ac !important;
  font-weight: 500;
  border-radius: 8px;
  text-transform: none !important;
  background-color: white !important;
}
