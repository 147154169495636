.input-field {
  display: flex !important;
  color: #038edb !important;

  .MuiInputBase-root {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.275rem 1rem !important;
    gap: 8px;
    background: #ffffff;
    border-radius: 0.4rem !important;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    font-weight: 400 !important;
    height: 2.1875 !important;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #afafaf !important;
      }

      input {
        &::placeholder {
          opacity: 1;
          color: #373737 !important;
          font-size: 0.875rem;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      &:hover {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        border-color: #afafaf !important;
      }
    }

    input {
      padding: 0;
      border: none !important;

      &::placeholder {
        opacity: 1;
        color: #afafaf;
        font-size: 0.875rem;
      }
    }
    .MuiOutlinedInput-input {
      font-size: 0.92rem;
      font-weight: 400 !important;
    }

    &.Mui-error {
      border-color: #d32f2f !important;
    }
    &:focus-within {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid !important;
        border-color: #038edb !important;
      }
    }
  }
  input {
    height: auto;
    padding: 0 !important;
    line-height: 1.52857;
    font-size: 0.875rem;
  }
}
