.row {
  .item-row {
    border: 1px solid #e4e4e4;
    text-align: center !important;
    padding: 17px;
    color: #666666;
  }

  &:hover {
    background-color: #f1f8fa !important;
  }
}

.bg-create {
  width: fit-content;
  color: white;
  background-color: rgb(23, 116, 46);
  padding: 4px 8px;
  border-radius: 25px;
}

.bg-update {
  width: fit-content;
  color: white;
  background-color: rgba(248, 175, 63);
  padding: 4px 8px;
  border-radius: 25px;
}

.item-colum {
  border: 1px solid #e4e4e4;
  font-weight: 550 !important;
  height: 32px;
  background: #fafafa !important;
  padding: 8px 0 !important;
  text-align: center !important;
  font-size: 0.775rem;
}
