.container-table-type {
  display: flex !important;
  justify-content: center;
  .MuiPaper-root {
    width: unset !important;
    .MuiTable-root {
      display: table;
    }
  }
}
