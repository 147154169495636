.search-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 1rem !important;
  gap: 8px;
  background: #ffffff;
  border-radius: 0.45rem !important;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  font-weight: 400 !important;
  width: 100%;
  height: 1.9rem !important;

  .MuiInputAdornment-root {
    margin-right: unset;
    margin-left: 5px;
  }

  .MuiSvgIcon-root {
    color: #afafaf;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: #afafaf !important;
    }

    input {
      &::placeholder {
        opacity: 1;
        color: #373737 !important;
        font-size: 0.875rem;
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border-color: #afafaf !important;
    }
  }

  input {
    font-size: 0.85rem;
    padding: 0;
    border: none !important;

    &::placeholder {
      opacity: 1;
      color: #afafaf;
      font-size: 0.875rem;
    }
  }

  &.Mui-error {
    border-color: #d32f2f !important;
  }

  &:focus-within {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid !important;
      border-color: #038edb !important;
    }
  }
}
