.expand-table-order {
  box-shadow: unset !important;
  width: 100%;
  margin-top: 20px;
  // overflow-x: unset !important;

  overflow-x: unset;
  .view-row {
    display: flex;
    width: 100%;
  }

  .img-row {
    max-width: 4rem;
    height: 2rem;
  }
  .view-tracking-table {
    width: 10%;
    align-items: flex-start;
    .view-info-tracking {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
    }
    .info-title {
      justify-content: flex-start;
      display: flex;
      width: 110px;
      flex-shrink: 0;

      .txt-tracking {
        font-family: 'SourceSansPro';
        color: #666666;
        font-weight: 600;
        font-size: 0.875rem;
        white-space: nowrap;
      }
    }
    .info-txt {
      width: 50%;
      justify-content: flex-start;
      flex-grow: 1;
      display: flex;
      .txt-tracking {
        font-size: 0.8rem;
      }
    }
  }
  .class-name-view {
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #f7f7f7;
    justify-content: space-around;
  }
  .input-field-detail {
    display: flex !important;
    color: white !important;

    .MuiInputBase-root {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.22rem 4rem !important;
      gap: 8px;
      background: #ffffff;
      border-radius: 0.3rem !important;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
      font-weight: 400 !important;
      height: 2 !important;
      font-family: 'SourceSansPro';
      color: #4d4d4d;
      font-weight: 400;
      font-size: 0.875rem;
      // width: 17rem;
      // @media (max-width: 900px) {
      //   width: 16rem !important;
      // }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #afafaf !important;
        }

        input {
          &::placeholder {
            opacity: 1;
            color: #afafaf !important;
            font-size: 0.875rem;
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          border-color: transparent !important;
        }
      }

      input {
        padding: 0;
        border: none !important;

        &::placeholder {
          opacity: 1;
          color: #afafaf;
          font-size: 0.875rem;
        }
      }
      .MuiOutlinedInput-input {
        font-size: 0.92rem;
        font-weight: 400 !important;
      }

      &:focus-within {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid !important;
          border-color: #afafaf !important;
        }
      }
    }
    input {
      height: auto;
      padding: 0 !important;
      line-height: 1.52857;
      font-size: 0.875rem;
    }
  }
  .input-field-detail-fix {
    display: flex !important;
    color: white !important;

    .MuiInputBase-root {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: #ffffff;
      border-radius: 0.3rem !important;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
      font-weight: 400 !important;
      height: 2 !important;
      font-family: 'SourceSansPro';
      color: #4d4d4d;
      font-weight: 400;
      font-size: 0.875rem;
      // width: 17rem;
      // @media (max-width: 900px) {
      //   width: 16rem !important;
      // }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #afafaf !important;
        }

        input {
          &::placeholder {
            opacity: 1;
            color: #afafaf !important;
            font-size: 0.875rem;
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        &:hover {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          border-color: transparent !important;
        }
      }

      input {
        padding: 0;
        border: none !important;

        &::placeholder {
          opacity: 1;
          color: #afafaf;
          font-size: 0.875rem;
        }
      }
      .MuiOutlinedInput-input {
        font-size: 0.92rem;
        font-weight: 400 !important;
      }

      &:focus-within {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid !important;
          border-color: #afafaf !important;
        }
      }
    }
    input {
      height: auto;
      padding: 0 !important;
      line-height: 1.52857;
      font-size: 0.875rem;
    }
  }
  .header-button {
    display: flex !important;
    justify-content: space-evenly !important;
    margin-inline: 1.5%;
  }
  .title-product {
    font-size: 0.875rem;
    font-weight: 600;
    color: #4d4d4d;
    font-family: 'SourceSansPro';
    margin-right: 10px;
  }
  .product-txt {
    font-size: 0.875rem;
    font-family: 'SourceSansPro';
    color: #4d4d4d;
    font-weight: 400;
  }
  .view-field {
    padding: 4px 10px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    background-color: white;
    // width: 8rem;
    margin-right: 5px;
    span {
      font-size: 14;
    }
  }
  .checkbox {
    .MuiButtonBase-root {
      padding: unset !important;
    }
  }
  .MuiPaper-root {
    .MuiTableContainer-root {
      border-radius: unset !important;
    }
  }

  .MuiTable-root {
    .MuiTableRow-hover:hover {
      background-color: #76b8cd1a;
    }

    .MuiTableRow-selected {
      background-color: #76b8cd1a;
    }

    .MuiTableHead-root {
      border: 1px solid #e4e4e4;
      background-color: rgba(245, 245, 245, 0.5);
      .MuiButtonBase-root {
        padding: unset !important;
      }

      .MuiTableCell-root {
        padding: unset;
        padding-top: 8px;
        padding-bottom: 8px;
        border: 1px solid #e4e4e4;
        font-family: 'SourceSansPro';
        color: #00202f;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        border: 1px solid #e4e4e4;

        .MuiTableCell-root {
          border: 1px solid #e4e4e4;
          font-size: 0.875rem;
          padding: 0.1rem 0.5rem;
          color: #666666;
          .text-color {
            .MuiInputBase-input {
              color: #666666;
            }
          }
        }
      }

      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          .MuiCollapse-wrapperInner {
            .MuiBox-root {
              .MuiTable-root {
                .MuiTableHead-root {
                  background-color: #f1f1f1;

                  .MuiTableRow-root {
                    .MuiTableCell-root {
                      font-family: 'SourceSansPro';
                      color: #00202f;
                    }
                  }
                }

                .MuiTableBody-root {
                  .MuiTableRow-root {
                    .MuiTableCell-root {
                      padding: 0.1rem 0.5rem !important;
                      word-break: break-word;
                      .MuiTouchRipple-root {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.content {
  .drawer-table {
    width: unset !important;
  }
  .tracking-table {
    width: unset !important;
  }
}

@media (max-width: 480px) {
  .mobile-scroll {
    width: 1200px !important;
  }
}
