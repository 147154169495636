.image-item {
  position: relative;
  width: 75px;
  height: 78px;
  margin-left: 5px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    border: 1px solid #d7d7d7 !important;
  }
  .close {
    position: absolute;
    right: -8px;
    top: -8px;
    color: #ff4d4f;
    cursor: pointer;
    min-width: 0;
    padding: 0;
    svg {
      color: #ff4d4f;
    }
  }
}

.loading-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px !important;
  width: 22px !important;
}

.view-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .txt-limit {
    font-size: 12px;
    color: #afafaf;
  }
}

.upload-image {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 75px !important;
  padding: 0 !important;
  svg {
    color: #afafaf;
    font-size: 2rem;
    height: 50px;
    width: 50px;
  }
}
.view-upload-length {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 79px;
  width: 79px;
  background-color: #e5e5e5;
  margin-left: 10px;
  border-radius: 5px;
}
